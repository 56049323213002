@tailwind base;
@tailwind components;
@tailwind utilities;

.tw-header-area {

}
.tw-profile-icon-area {
    float: left;
}

.tw-profile-icon {
    transform: rotate( -15deg );
    border-radius: 50%;
}

.tw-profile-name-area {
    text-align: left;
    font-size: 1.0rem;
}

.tw-profile-name {
    float: left;
    margin-left: 0.4em; 
}

.tw-profile-date {
    text-align: right;
    font-size: .8rem;
}

.tw-text-area {
    text-align: left;
    margin: 3em 0.5em 1px 0.5em;
    font-size: 0.9rem;
    display: block;
}

.tw-more-area {
    text-align: right;
}

.search-inputform {
    width: 75%;
    text-align: center;
    margin: auto;
}

@media screen and (max-width: 768px) {
    .search-inputform {
        width: 95%;
        text-align: center;
        margin: auto;
    }
}